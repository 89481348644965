.shepherd-element.shepherd-has-title[data-popper-placement^=bottom] > .shepherd-arrow:before {
  background: var(--fivef-color-surface-primary) !important;
}

.shepherd-header {
  background: var(--fivef-color-surface-primary) !important;
}

.shepherd-button:not(:disabled):hover {
  color: var(--mdc-protected-button-label-text-color, inherit) !important;
  background-color: var(--mdc-protected-button-container-color, transparent) !important;
}

.fivef-Release-Tour {

  .shepherd-title {
    align-items: start !important;
  }

  .shepherd-text {
    padding: 0 !important;
  }

  .shepherd-footer {
    justify-content: center !important;
  }
}

.fivef-shepherd-header {
  box-shadow: 0 12px 36px 16px rgba(0, 0, 0, 0.24) !important;

  .shepherd-header {
    align-items: flex-start;
  }

  .shepherd-title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .shepherd-text {
    display: flex;
    flex-direction: column;
  }
}
